<template>
    <div class="project-count-container">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-3 count-column" v-for="(item, index) in projectCounts" :key="index">
                            <div class="count-item">
                                <span class="count-number">{{ animatedCounts[index] }}</span>
                                <p class="count-title">{{ item.title }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectCount',
    data() {
        return {
            projectCounts: [
                { title: 'Years of Experience', count: 5 },
                { title: 'Successful Projects', count: 10 },
                { title: 'Active Projects', count: 3 },
                { title: 'Happy Customers', count: 20 },
            ],
            animatedCounts: [0, 0, 0, 0],
        };
    },
    mounted() {
        this.projectCounts.forEach((item, index) => {
            this.animateCount(index, item.count, 2000);
        });
    },
    methods: {
        animateCount(index, endVal, duration) {
            const startVal = 0;
            const startTime = performance.now();
            const step = (currentTime) => {
                const progress = Math.min((currentTime - startTime) / duration, 1);
                this.animatedCounts[index] = Math.floor(progress * (endVal - startVal) + startVal);
                if (progress < 1) {
                    requestAnimationFrame(step);
                }
            };
            requestAnimationFrame(step);
        },
    },
};
</script>

<style scoped>
.project-count-container {
    padding: 60px 0;
    background-color: #F4F0EC;
}

.count-column {
    position: relative;
    padding: 20px;
    background-color: transparent;
    border-left: 2px solid #CDA274;
}

@media (max-width: 768px) {
    .count-column {
        border-left: none;
        border-bottom: 2px solid #CDA274;
    }
}

.count-column:first-child {
    border-left: none;
}

.count-item {
    position: relative;
}

.count-number {
    font-size: 2.5rem;
    font-weight: bold;
    color: #CDA274;
}

.count-title {
    font-size: 1.25rem;
    color: #6c757d;
}
</style>
