<template>
    <div class="contact-form-container">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <h2 class="text-center">Creative project? Let's have
                        a productive talk.</h2>
                    <form @submit.prevent="submitForm">
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <input type="text" class="form-control underlined" id="name" v-model="form.name"
                                        placeholder="Name" required>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <input type="email" class="form-control underlined" id="email" v-model="form.email"
                                        placeholder="Email" required>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <textarea class="form-control underlined" id="message" v-model="form.message" rows="5"
                                placeholder="Hello Iam Intrested in..." required></textarea>
                        </div>
                        <button type="submit" class="btn custom-btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactUsForm',
    data() {
        return {
            form: {
                name: '',
                email: '',
                message: '',
            },
        };
    },
    methods: {
        submitForm() {
            // Handle form submission
            console.log('Form submitted:', this.form);
            this.resetForm();
        },
        resetForm() {
            this.form.name = '';
            this.form.email = '';
            this.form.message = '';
        },
    },
};
</script>

<style scoped>
.contact-form-container {
    padding: 60px 0;
    background-color: #FFFFFF;
}

.contact-form-container h2 {
    margin-bottom: 40px;
    font-family: serif;
}

.contact-form-container .form-control {
    margin-bottom: 20px;
    border: none;
    border-bottom: 2px solid #CDA274;
    /* Underlined dashed border */
    padding: 10px;
    font-family: Arial;
}

.contact-form-container .btn {
    display: block;
    width: 100%;
}

h2 {
    font-family: serif;
}

.custom-btn {
    background-color: #292F36;
    color: #fff;
    border: none;
    font-family: Arial;
    font-size: 0.9rem;
}

.custom-btn:hover {
    background-color: #292F36;
    color: #fff;
}
</style>