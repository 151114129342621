<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Serif+Display:wght@400;700&display=swap');

body {
  font-family: 'Serif Display', serif;
}
</style>

