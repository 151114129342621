<template>
    <div class="our-services-container">
        <div class="container">
            <div class="row justify-content-center mb-5">
                <div class="col-8 text-center">
                    <h1 class="display-4 fw-bold">Our Services</h1>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 mb-4" v-for="(service, index) in services" :key="index">
                            <div class="card h-100 text-center transparent-card">
                                <div class="card-body">
                                    <img :src="require(`@/assets/${service.icon}`)" class="card-img-top"
                                        :alt="`Service Name ${index + 1}`">
                                    <h5 class="card-title">{{ service.title }}</h5>
                                    <p class="card-text">{{ service.description }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurServices',
    data() {
        return {
            services: [
                {
                    icon: 'arch_icon.png',
                    title: 'Architecture',
                    description: 'From conceptualization to construction, we specialize in creating architectural wonders.',
                },
                {
                    icon: 'int_icon.png',
                    title: 'Interiors',
                    description: 'Crafting interiors that evoke serenity and connection with the natural world, while reflecting your unique style and preferences.',
                },
                {
                    icon: 'land_icon.png',
                    title: 'Landscape',
                    description: 'Transforming outdoor spaces into lush, green sanctuaries that complement the architectural design.',
                },
            ],
        };
    },
};
</script>

<style scoped>
.our-services-container {
    padding: 30px 0;
    background-color: #F4F0EC;
}

h1 {
    margin-bottom: 20px;
    font-family: serif;
}

p {
    margin-bottom: 40px;
}

.card {
    border: none;
    border-radius: 10px;
}

.transparent-card {
    background-color: #F4F0EC;
    /* Transparent background */
}

.card-img-top {
    height: 80px;
    width: 80px;
    object-fit: cover;
}

.card-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 10px;
    font-family: serif;
}

.card-text {
    font-size: 1rem;
    color: #6c757d;
    font-family: Arial;
}
</style>