<template>
    <div class="our-services-container">
        <div class="container">
            <div class="row justify-content-center mb-5">
                <div class="col-8 text-center">
                    <h1 class="display-4 fw-bold">Our Approach</h1>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 mb-4" v-for="(service, index) in services" :key="index">
                            <div class="card h-100 text-center transparent-card">
                                <div class="card-body">
                                    <h5 class="card-title">{{ service.title }}</h5>
                                    <p class="card-text">{{ service.description }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'OurApproach',
    data() {
        return {
            services: [
                {
                    title: 'Client Consultation',
                    description: 'Understanding your vision, preferences, and requirements.',
                },
                {
                    title: 'Design Development',
                    description: 'Translating your ideas into stunning design renders that resonate with nature.',
                },
                {
                    title: 'Execution',
                    description: 'Bringing designs to life on-site or providing detailed plans for your preferred contractors to execute.',
                },
            ],
        };
    },
};
</script>

<style scoped>
.our-services-container {
    padding: 30px 0;
    background-color: #FFFFFF;
}

h1 {
    margin-bottom: 20px;
    font-family: serif;
}

p {
    margin-bottom: 40px;
}

.card {
    border: none;
    border-radius: 10px;
}

.transparent-card {
    background-color: #FFFFFF;
    /* Transparent background */
}

.card-img-top {
    height: 80px;
    width: 80px;
    object-fit: cover;
}

.card-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 10px;
    font-family: serif;
}

.card-text {
    font-size: 1rem;
    color: #6c757d;
    font-family: Arial;
}
</style>