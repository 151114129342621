<template>
    <div>
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12 col-md-8">
                    <nav class="navbar navbar-expand-lg navbar-light bg-transparent">
                        <a class="navbar-brand" href="/">
                            <img src="@/assets/nav_logo.png" alt="Logo" class="d-inline-block align-top brand-logo"
                                height="100" />
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a class="nav-link active" aria-current="page" href="/">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="/portfolio">Portfolio</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="/aboutus">About Us</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>

        <!-- SubPage Header -->
        <SubPageHeader title="Project Title" subtitle="Home/Portfolio/Project Title" />

        <ProjectDescription />

        <!-- Footer -->
        <FooterSection />
    </div>
</template>

<script>
import FooterSection from '@/components/Footer_Section.vue';
import SubPageHeader from '@/components/SubPage_Header.vue';
import ProjectDescription from '@/components/Project_Description.vue';

export default {
    name: 'ProjectPage1',
    components: {
        FooterSection,
        SubPageHeader,
        ProjectDescription,
    },
};
</script>

<style scoped>
@media (max-width: 768px) {
    .brand-logo {
        width: auto;
        height: 80px;
    }
}
</style>
