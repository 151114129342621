<template>
    <div class="projects-home-container">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mb-4" v-for="(project, index) in paginatedProjects" :key="index">
                    <div class="project-card" :style="{ height: getCardHeight(index) }">
                        <img :src="require(`@/assets/${project.image}`)" alt=" Project Image"
                            :class="['img-fluid', 'project-image']">
                        <div class="card-body d-flex flex-column transparent-text-area">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="card-title">{{ project.title }}</h5>
                                <a href="/project-details" class="btn btn-arrow">
                                    <i class="fas fa-arrow-right"></i>
                                </a>
                            </div>
                            <p class="card-text">{{ project.subtitle }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination-container">
                <span v-for="page in totalPages" :key="page"
                    :class="{ 'page-number': true, 'active': currentPage === page }" @click="goToPage(page)">
                    {{ page }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PortfolioHome',
    data() {
        return {
            projects: [
                {
                    image: 'project_image1.png',
                    title: 'Project Title 1',
                    subtitle: 'Project Subtitle 1',
                },
                {
                    image: 'project_image2.png',
                    title: 'Project Title 2',
                    subtitle: 'Project Subtitle 2',
                },
                {
                    image: 'project_image1.png',
                    title: 'Project Title 1',
                    subtitle: 'Project Subtitle 1',
                },
                {
                    image: 'project_image2.png',
                    title: 'Project Title 2',
                    subtitle: 'Project Subtitle 2',
                },
                {
                    image: 'project_image1.png',
                    title: 'Project Title 1',
                    subtitle: 'Project Subtitle 1',
                },
                {
                    image: 'project_image2.png',
                    title: 'Project Title 2',
                    subtitle: 'Project Subtitle 2',
                },
                {
                    image: 'project_image1.png',
                    title: 'Project Title 1',
                    subtitle: 'Project Subtitle 1',
                },
                {
                    image: 'project_image2.png',
                    title: 'Project Title 2',
                    subtitle: 'Project Subtitle 2',
                },
                {
                    image: 'project_image1.png',
                    title: 'Project Title 1',
                    subtitle: 'Project Subtitle 1',
                },
                {
                    image: 'project_image2.png',
                    title: 'Project Title 2',
                    subtitle: 'Project Subtitle 2',
                },
                {
                    image: 'project_image1.png',
                    title: 'Project Title 1',
                    subtitle: 'Project Subtitle 1',
                },
                {
                    image: 'project_image2.png',
                    title: 'Project Title 2',
                    subtitle: 'Project Subtitle 2',
                },
                // Add more projects as needed
            ],
            currentPage: 1,
            projectsPerPage: 5,
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.projects.length / this.projectsPerPage);
        },
        paginatedProjects() {
            const start = (this.currentPage - 1) * this.projectsPerPage;
            const end = start + this.projectsPerPage;
            return this.projects.slice(start, end);
        },
    },
    methods: {
        goToPage(page) {
            this.currentPage = page;
        },
        getCardHeight(index) {
            return index % 3 === 0 ? '300px' : '400px';
        },
    },
};
</script>

<style scoped>
.projects-home-container {
    padding: 60px 0;
    background-color: #f8f9fa;
}

h1 {
    margin-bottom: 20px;
    font-family: serif;
}

h5 {
    font-family: serif;
    font-weight: 800;
}

p {
    margin-bottom: 40px;
    font-family: Arial;
    font-size: 0.8rem;
    color: #6c757d;
}

.project-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.project-image {
    width: 100%;
    height: 75%;
    object-fit: cover;
}

.project-info {
    text-align: center;
    margin-top: 10px;
}

.project-title {
    font-size: 1.25rem;
    font-weight: bold;
}

.project-subtitle {
    font-size: 1rem;
    color: #6c757d;
}

.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.page-number {
    height: 30px;
    width: 30px;
    margin: 0 5px;
    background-color: #F4F0EC;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.page-number.active {
    background-color: #CDA274;
    color: #fff;
}
</style>
