import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './pages/HomePage.vue';
import AboutUs from './pages/AboutUs.vue';
import PortfolioPage from './pages/PortfolioPage.vue';
import ProjectPage1 from './pages/ProjectPage1.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/aboutus',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/portfolio',
    name: 'PortfolioPage',
    component: PortfolioPage,
  },
  {
    path: '/project-details',
    name: 'ProjectPage1',
    component: ProjectPage1,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;