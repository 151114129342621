<template>
    <div class="subpage-header">
        <img src="@/assets/header_image.png" alt="Subpage Header Image" class="header-image">
        <div class="header-overlay">
            <h1 class="header-title">{{ title }}</h1>
            <p class="header-subtitle">{{ subtitle }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SubPageHeader',
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.subpage-header {
    width: 100%;
    height: 300px;
    /* Fixed height */
    overflow: hidden;
    position: relative;
}

.header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the image covers the entire area */
    position: absolute;
    top: 0;
    left: 0;
}

.header-overlay {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    /* White background with some transparency */
    padding: 20px 60px;
    border-radius: 10px;
    text-align: center;
}

.header-title {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    font-family: serif;
}

.header-subtitle {
    margin: 0;
    font-size: 0.8rem;
    color: #666;
    font-family: Arial;
}
</style>