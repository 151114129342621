<template>
    <footer class="footer-container">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-8">
                    <div class="row">
                        <div class="col-md-4 text-center text-md-left">
                            <img src="@/assets/nav_logo.png" alt="Logo" class="footer-logo mb-4">
                            <div>
                                <a href="https://www.instagram.com/terra.designhouse/" target="_blank"
                                    class="instagram-link">
                                    <i class="fab fa-instagram"></i>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-4 text-center">
                            <h5>Pages</h5>
                            <ul class="list-unstyled">
                                <li><a href="/">Home</a></li>
                                <li><a href="/portfolio">Portfolio</a></li>
                                <li><a href="/aboutus">About Us</a></li>
                                <li><a href="/contact">Contact</a></li>
                            </ul>
                        </div>
                        <div class="col-md-4 text-center text-md-left">
                            <h5>Contact Us</h5>
                            <p>Jubilee Hills</p>
                            <p>Hyderabad, Telangana.</p>
                            <p>Email: contact@terradesignhouse.in</p>
                            <p>Phone: +91-9104712347</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer_Section',
};
</script>

<style scoped>
.footer-container {
    padding: 40px 0;
    background-color: #f8f9fa;
    border-top: 1px solid #ddd;
}

.footer-logo {
    max-width: 100%;
    height: auto;
    max-height: 80px; /* Adjust this value as needed */
}

.instagram-link {
    color: #C13584;
    font-size: 1.5rem;
}

.instagram-link:hover {
    color: #e1306c;
}

h5 {
    margin-bottom: 20px;
    font-family: serif;
}

ul {
    padding: 0;
    list-style: none;
}

ul li {
    margin-bottom: 10px;
}

ul li a {
    color: #6c757d;
    text-decoration: none;
}

ul li a:hover {
    color: #343a40;
}

p {
    margin-bottom: 10px;
    color: #6c757d;
}
/* Media query for smaller screens */
@media (max-width: 768px) {
    .footer-logo {
        width: auto;
        height: 80px;
    }
}
</style>