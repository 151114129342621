<template>
    <div>
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12 col-md-8">
                    <nav class="navbar navbar-expand-lg navbar-light bg-transparent">
                        <a class="navbar-brand" href="/">
                            <img src="@/assets/nav_logo.png" alt="Logo" class="d-inline-block align-top brand-logo"
                                height="100" />
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a class="nav-link active" aria-current="page" href="/">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="/portfolio">Portfolio</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="/aboutus">About Us</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>

        <!-- SubPage Header -->
        <SubPageHeader title="About Us" subtitle="Home/About Us" />

        <!-- About Us Content -->
        <div class="about-us-container">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-8">
                        <div class="row align-items-center mb-5 flex-md-row-reverse">
                            <div class="col-md-6">
                                <img src="@/assets/aboutus1.png" alt="About Image 1" class="img-fluid rounded">
                            </div>
                            <div class="col-md-6">
                                <h2 class="fw-bold">Our Approach</h2>
                                <p>
                                    It is rooted in a deep understanding of the interplay between space, form, and
                                    aesthetics. We believe that every project is an opportunity to create something
                                    unique and impactful, whether it’s a cozy residential space, a sprawling commercial
                                    complex, or a serene landscape.
                                </p>
                            </div>
                        </div>
                        <div class="row align-items-center mb-5">
                            <div class="col-md-6">
                                <img src="@/assets/aboutus2.png" alt="About Image 2" class="img-fluid rounded">
                            </div>
                            <div class="col-md-6">
                                <h2 class="fw-bold">Our Commitment</h2>
                                <p>
                                    It is to that excellence is reflected in our meticulous attention to detail and our
                                    dedication to realizing our clients’ visions. With each project, we strive to push
                                    the boundaries of design, creating spaces that are not only functional but also
                                    inspire and elevate the human experience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="centerpiece-container">
            <div class="centerpiece">
                Terra Design House is where creativity meets practicality, resulting in designs that are both innovative
                and timeless. Join us on a journey of transformation, where your vision becomes our blueprint for the
                extraordinary.
            </div>
        </div>

        <ContactUsForm />
        <!-- Footer -->
        <FooterSection />
    </div>
</template>

<script>
import FooterSection from '@/components/Footer_Section.vue';
import SubPageHeader from '@/components/SubPage_Header.vue';
import ContactUsForm from '@/components/ContactUs_Form.vue';

export default {
    name: 'AboutUs',
    components: {
        FooterSection,
        SubPageHeader,
        ContactUsForm,
    },
};
</script>

<style scoped>
.about-us-container {
    padding: 60px 0;
    background-color: #ffffff;
}

h2 {
    margin-bottom: 20px;
    font-family: serif;
}

p {
    margin-bottom: 20px;
    color: #6c757d;
}

.centerpiece-container {
    display: flex;
    justify-content: center;
    padding: 40px 0;
    background-color: #ffffff;
}

.centerpiece {
    padding: 20px;
    border-left: 10px solid #F4F0EC;
    border-right: 10px solid #F4F0EC;
    border-radius: 20px;
    font-size: 1.25rem;
    color: #333;
    text-align: center;
    max-width: 800px;
}
@media (max-width: 768px) {
    .brand-logo {
        width: auto;
        height: 80px;
    }
}
</style>
