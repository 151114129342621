<template>
    <div class="project-description-container">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-8">
                    <div class="row mb-5 align-items-stretch">
                        <div class="col-md-4 d-flex">
                            <div class="highlight-box p-3 text-center d-flex flex-column justify-content-center">
                                <div class="d-flex justify-content-between">
                                    <p class="heading"><strong>Client:</strong></p>
                                    <p class="value">{{ project.client }}</p>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p class="heading"><strong>Category:</strong></p>
                                    <p class="value">{{ project.category }}</p>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p class="heading"><strong>Tags:</strong></p>
                                    <p class="value">{{ project.tags.join(', ') }}</p>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p class="heading"><strong>Date:</strong></p>
                                    <p class="value">{{ project.date }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <h1 class="project-title">{{ project.title }}</h1>
                            <p class="project-description">{{ project.description }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <img :src="project.image" alt="Project Image" class="img-fluid rounded">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectDescription',
    data() {
        return {
            project: {
                title: 'Project Title',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquamsem vitae turpis dignissim maximus. Aliquam sollicitudin tellumassa, vbel maximus purus posuere in. Dojrices gravida dignissim. Praesent at nibh in mi fringilla mattis. Phasellus ut dolor odio. Aenean in the ipsum vel lectus bibendum commodo. In nec sem suscipit, convallis leo vitae, lacinia nibh.Cras amet tellus lectus.Vivamus ipsum nunc, mattis quis nibh id, pellentesque arcu.Donec a pellentesque Cras erat enim, gravida non ante vitae, elequis convallis elit, in viverra felis.Donec ultrices tellus vitae iaculisvd porta.Proin tincidunt ligula id purus porttitor.',
                client: 'Client Name',
                category: 'Project Category',
                tags: ['Tag1', 'Tag2', 'Tag3'],
                date: 'January 1, 2023',
                image: 'https://via.placeholder.com/1200x800',
            },
        };
    },
};
</script>

<style scoped>
.project-description-container {
    padding: 60px 0;
    background-color: #ffffff;
}

.project-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.project-description {
    font-size: 0.8rem;
    color: #6c757d;
    margin-bottom: 20px;
}

.highlight-box {
    background-color: #F4F0EC;
    border-radius: 10px;
}

.highlight-box p {
    margin-bottom: 10px;
}

.img-fluid {
    width: 100%;
    height: auto;
}

h1 {
    font-family: serif;
}

.heading {
    font-weight: bold;
    font-family: serif;
    font-size: 0.8rem;
    padding-right: 10px;
}

.value {
    font-family: Arial;
    font-size: 0.8rem;
}
</style>