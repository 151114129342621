<template>
    <div class="reviews-container">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-8 bg-light-custom p-4" style="border-radius: 15px;">
                    <div class="row justify-content-center mb-5">
                        <div class="col-8 text-center">
                            <h1 class="display-4 fw-bold">What the People Think About Us</h1>
                        </div>
                    </div>

                    <div class="reviews-scroll" @scroll="onScroll">
                        <div class="card review-card" v-for="(review, index) in reviews" :key="index">
                            <div class="card-body">
                                <h5 class="card-title">{{ review.name }}</h5>
                                <p class="card-text">{{ review.text }}</p>
                                <div class="rating">
                                    <i v-for="star in review.rating" :key="star" class="fas fa-star"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReviewsSection',
    data() {
        return {
            reviews: [
                {
                    name: 'John Doe',
                    text: 'Excellent service and great attention to detail. Highly recommended!',
                    rating: 5,
                },
                {
                    name: 'Jane Smith',
                    text: 'The team was professional and delivered outstanding results.',
                    rating: 4,
                },
                {
                    name: 'Michael Johnson',
                    text: 'Great experience from start to finish. Will definitely use their services again.',
                    rating: 5,
                },
                {
                    name: 'Emily Davis',
                    text: 'Very satisfied with the quality of work and customer service.',
                    rating: 4,
                },
            ],
            currentIndex: 0,
        };
    },
    methods: {
        onScroll(event) {
            const scrollLeft = event.target.scrollLeft;
            const cardWidth = event.target.querySelector('.review-card').offsetWidth;
            this.currentIndex = Math.round(scrollLeft / cardWidth);
        },
        scrollToReview(index) {
            const cardWidth = this.$el.querySelector('.review-card').offsetWidth;
            this.$el.querySelector('.reviews-scroll').scrollLeft = index * cardWidth;
            this.currentIndex = index;
        },
    },
};
</script>

<style scoped>
.reviews-container {
    padding: 60px 0;
    background-color: #FFFFFF;
}

h1 {
    margin-bottom: 20px;
    font-family: serif;
}

p {
    margin-bottom: 40px;
}

.reviews-scroll {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding-bottom: 10px;
}

.reviews-scroll::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}

.review-card {
    flex: 0 0 auto;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 30%;
    height: 60%;
}

.card-body {
    padding: 20px;
}

.card-title {
    font-size: 1.25rem;
    font-weight: bold;
}

.card-text {
    font-size: 1rem;
    color: #6c757d;
}

.rating {
    color: #CDA274;
}

.bg-light-custom {
    width: 80%;
    background-color: #F4F0EC !important;
}

@media (max-width: 768px) {
    .review-card {
        width: 85%;
    }
}
</style>
