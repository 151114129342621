<template>
  <div>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <nav class="navbar navbar-expand-lg navbar-light bg-transparent">
            <a class="navbar-brand" href="/">
              <img src="@/assets/nav_logo.png" alt="Logo" class="d-inline-block align-top brand-logo" height="100" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="/">Home</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/portfolio">Portfolio</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/aboutus">About Us</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="hero-container position-relative overflow-hidden text-center">
      <img src="@/assets/hero_img.png" class="hero-image img-fluid w-100" alt="Hero Image">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <div class="position-absolute top-50 translate-middle-y text-overlay">
            <h1 class="display-4 fw-normal">Crafting</h1>
            <h1 class="display-4 fw-normal">Extraordinary</h1>
            <h1 class="display-4 fw-normal">Spaces</h1>
            <p class="lead fw-normal">Your vision, our blueprint at Terra Design House</p>
            <a class="btn custom-btn btn-lg" href="/portfolio">Get Started</a>
          </div>
        </div>
      </div>
    </div>

    <Projects_Home />
    <Our_Services />
    <Our_Approach />
    <ProjectCount />
    <ReviewsSection />
    <FooterSection />
  </div>
</template>

<script>
import Projects_Home from '@/components/Projects_Home.vue';
import Our_Services from '@/components/Our_Services.vue';
import Our_Approach from '@/components/Our_Approach.vue';
import ProjectCount from '@/components/Project_count.vue';
import ReviewsSection from '@/components/Reviews_Section.vue';
import FooterSection from '@/components/Footer_Section.vue';

export default {
  name: 'HomePage',
  components: {
    Projects_Home,
    Our_Services,
    Our_Approach,
    ProjectCount,
    ReviewsSection,
    FooterSection,
  },
};
</script>

<style scoped>
.hero-container {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom-left-radius: 150px;
}

.text-overlay {
  color: #292F36;
  text-align: left;
  padding-left: 2rem;
  font-family: serif;
}

h1 {
  text-align: left;
  font-family: serif;
}

.navitem {
  font-family: Arial;
}

.custom-btn {
  background-color: #292F36;
  color: #fff;
  border: none;
  font-family: Arial;
  font-size: 0.9rem;
}

.custom-btn:hover {
  background-color: #292F36;
  color: #fff;
}

@media (max-width: 768px) {
  .brand-logo {
    width: auto;
    height: 80px;
  }

  .hero-container {
    padding: 1rem;
  }

  .text-overlay {
    padding-left: 1rem;
    text-align: center;
  }

  .text-overlay h1 {
    font-size: 1.5rem;
    margin-top: 0.2rem;
  }

  .text-overlay p {
    font-size: 1rem;
  }

  .custom-btn {
    font-size: 0.8rem;
  }

  .hero-image {
    border-bottom-left-radius: 50px;
  }
}

@media (max-width: 450px) {
  .hero-image {
    border-bottom-left-radius: 20px;
  }

  .hero-container {
    position: relative;
    text-align: center;
    padding: 0.4rem;
  }

  .text-overlay h1 {
    font-size: 1rem;
    margin: 0rem;
  }

  .text-overlay p {
    font-size: 0.8rem;
    text-align: left;
    margin: 0rem;
  }

  .custom-btn {
    font-size: 0.5rem;
    padding: 0.5rem 1rem;
    margin-top: 1.2rem;
    position: absolute;
    top: 75%;
    left: 50%;
  }

}
</style>