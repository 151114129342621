<template>
    <div class="projects-container">
        <div class="container">
            <div class="row justify-content-center mb-5">
                <div class="col-8 text-center">
                    <h1 class="display-4 fw-bold">Explore Our Projects</h1>
                    <p class="lead">
                        Dive into the world of our design marvels. Our projects are a testament to our commitment to
                        aesthetic excellence, functionality, and sustainability. From residential complexes that blend
                        comfort and style, to commercial buildings that embody professionalism and efficiency, our
                        portfolio is diverse and expansive.
                    </p>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 mb-4" v-for="(project, index) in projects" :key="index">
                            <div class="card h-100">
                                <img :src="require(`@/assets/${project.image}`)" class="card-img-top"
                                    :alt="`Project Image ${index + 1}`">
                                <div class="card-body d-flex flex-column transparent-text-area">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h5 class="card-title">{{ project.title }}</h5>
                                        <a href="/project-details" class="btn btn-arrow">
                                            <i class="fas fa-arrow-right"></i>
                                        </a>
                                    </div>
                                    <p class="card-text">{{ project.subtitle }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectsHome',
    data() {
        return {
            projects: [
                {
                    image: 'project_image1.png',
                    title: 'Project Title 1',
                    subtitle: 'Project Subtitle 1',
                },
                {
                    image: 'project_image2.png',
                    title: 'Project Title 2',
                    subtitle: 'Project Subtitle 2',
                },
                {
                    image: 'project_image3.png',
                    title: 'Project Title 3',
                    subtitle: 'Project Subtitle 3',
                },
                {
                    image: 'project_image4.png',
                    title: 'Project Title 4',
                    subtitle: 'Project Subtitle 4',
                },
            ],
        };
    },
};
</script>

<style scoped>
.projects-container {
    padding: 60px 0;
    background-color: #f8f9fa;
}

h1 {
    margin-bottom: 20px;
    font-family: serif;
}

p {
    margin-bottom: 40px;
}

.card {
    border: none;
    border-radius: 10px;
    overflow: hidden;
    background-color: transparent;
}

.card-img-top {
    height: 400px;
    object-fit: cover;
}

.card-title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: left;
    font-family: serif;
}

.card-text {
    font-size: 1rem;
    color: #6c757d;
    text-align: left;
    font-family: Arial;
}
</style>